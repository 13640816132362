<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div style="text-align: center">
        <a-spin v-if="loading" />
      </div>
      <div class="d-flex justify-content-start flex-wrap" style="width: 500px; border: 1px solid #ddd; padding: 20px; margin: 0 auto;" v-if="loading === false">
        <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
          <strong>Your Plan</strong>
          <div>
            <router-link to="/plans" class="ml-auto" style="font-size: 12px;">Change Plan</router-link>
          </div>
        </div>
        <div class="flex-fill" style="margin-top: 20px;">
          <div style="font-size: 18px;">{{current_plan.name}}</div>
          <div style="font-size: 16px;">${{current_plan.prices ? current_plan.prices.monthly.price : 0}} / month</div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import plans from '@/constants/plans';
import ASpin from 'ant-design-vue/lib/spin/index';
import 'ant-design-vue/lib/spin/style/index.css';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ASpin
  },
  data() {
    return {
      loading: false,
      current_plan: {}
    }
  },
  async mounted() {
    document.title = 'Manage Subscription - Mane Street Market';
    window.scrollTo(0, 0);
    await this.loadSubscriptionDetails();
  },
  methods: {
    async loadSubscriptionDetails() {
      this.loading = true;
      try {
        let response = await axios.get('/api/current-subscription');
        this.current_plan = plans[response.data.last_package_name];
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>